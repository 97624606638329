const acsDirectoriesRoutes = [
  {
    path: '/acs-directories/acs-access-lists',
    name: 'acsPremises',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'CКУД', disabled: true },
        { text: 'Списки доступа', disabled: true },
      ],
    },
    component: () => import('@/components/AcsDirectories/AcsAccessLists.vue'),
  },
  {
    path: '/acs-directories/acs-keys',
    name: 'acsKeys',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'CКУД', disabled: true },
        { text: 'Ключи', disabled: true },
      ],
    },
    component: () => import('@/components/AcsDirectories/AcsKeys.vue'),
  },
];

export default acsDirectoriesRoutes;
