/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const devicesStore = {
  namespaced: true,
  state: {
    devicesList: [],
    totalDevices: 0,

    devicesModels: [],
    devicesModelsCount: 0,

    devicesDrivers: [],
    devicesDriversCount: 0,

    currentEditedDevice: { md_id: 0 },
  },
  getters: {
    editedDevice: (state) => state.currentEditedDevice,
  },

  mutations: {
    SET_DEVICES(state, payload) {
      state.devicesList = payload.data;
      state.totalDevices = payload.total;
    },
    SET_DEVICES_MODELS(state, payload) {
      state.devicesModels = payload.data;
      state.devicesModelsCount = payload.total;
    },
    SET_DEVICES_DRIVERS(state, payload) {
      state.devicesDrivers = payload.data;
      state.devicesDriversCount = payload.total;
    },
    SET_EDITED_DEVICE(state, payload) {
      state.currentEditedDevice = { ...payload };
    },
  },
  actions: {
    async devicesSave(context, payload) {
      await req.post('devicesSave', {
        obj: { ...payload },
      });
    },
    async getDevices(context, payload) {
      const { data } = await req.post('devicesGetlist', payload);
      context.commit('SET_DEVICES', data);
    },
    async getDevicesModels(context, payload) {
      const { data } = await req.post('devicesModels', payload);
      context.commit('SET_DEVICES_MODELS', data);
    },
    async getDevicesDrivers(context, payload) {
      const { data } = await req.post('devicesDrivers', payload);
      context.commit('SET_DEVICES_DRIVERS', data);
    },
    async updateDeviceModel(context, payload) {
      await req.post('deviceModelSave', {
        obj: { ...payload },
      });
    },
  },
};

export default devicesStore;
